import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  container: {
    // ...
  },
  thumb: {
    bg: '#879D7F',
    _checked: {
      bg: '#F26B6A',
    },
    my: 'auto',
  },
  track: {
    bg: 'transparent',
    border: '1px solid #879D7F',
    _checked: {
      bg: 'transparent',
      border: '1px solid #F26B6A',
    },
  },
});

export default defineMultiStyleConfig({ baseStyle });
