export interface Config {
  appName: string;
  env: string;
  auth: {};
  database: {};
  server: {};
  aws: {};
  backend: {
    url: string;
  };
}

export const getEnvironmentValue = (
  key: string,
  defaultValue?: string
): string => {
  const envVal = process.env[key] || defaultValue;

  if (!envVal) {
    throw new Error(`env variable ${key} should be defined`);
  }

  return envVal;
};
