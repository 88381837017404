import { Config, getEnvironmentValue } from './util';

const config: Config = {
  appName: 'ara',
  env: getEnvironmentValue('REACT_APP_ENVIRONMENT', 'dev'),
  auth: {},
  database: {},
  aws: {},
  server: {},
  backend: {
    url: getEnvironmentValue(
      'REACT_APP_BACKEND_URL',
      'http://localhost:3000/dev/api/v1'
    ),
  },
};

export default config;
