import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import config from '../config';
import { loggedOut } from './features/authState';

const baseQuery = fetchBaseQuery({
  baseUrl: config.backend.url,
  mode: 'cors',
  credentials: 'include',
});

interface ErrorData {
  type?: string;
  [key: string]: any;
}

function isErrorData(data: any): data is ErrorData {
  return data && typeof data.type === 'string';
}

const baseQueryWithAuthLogout: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result.error && isErrorData(result.error.data)) {
    const { type } = result.error.data;

    if (type === 'E_UNAUTHORIZED') {
      api.dispatch(loggedOut());
    }
  }

  return result;
};

export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithAuthLogout,
  tagTypes: ['accountDetail', 'accounts'],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: 'api/v1/auth/login',
        method: 'POST',
        body,
      }),
    }),
    getMyInfo: builder.query({
      query: () => ({
        url: 'api/v1/auth/me',
        method: 'GET',
      }),
    }),

    // accounts
    getAccounts: builder.query({
      query: () => ({
        url: `api/v1/accounts?limit=10000`,
        method: 'GET',
      }),
      providesTags: (result, err, id) => [{ type: 'accounts', id }],
    }),
    getAccount: builder.query({
      query: (username: string) => ({
        url: `api/v1/accounts/${username}`,
        method: 'GET',
      }),
      providesTags: (result, err, id) => [{ type: 'accountDetail', id }],
    }),
    deactivateAccount: builder.mutation({
      query: ({ username, orgid }) => ({
        url: 'api/v1/accounts/deactivate',
        method: 'POST',
        body: {
          username,
          orgid,
        },
      }),
    }),
    activateAccount: builder.mutation({
      query: ({ username, orgid }) => ({
        url: 'api/v1/accounts/activate',
        method: 'POST',
        body: {
          username,
          orgid,
        },
      }),
    }),
    deleteAccount: builder.mutation({
      query: ({ username, orgid }) => ({
        url: 'api/v1/accounts/deactivate',
        method: 'POST',
        body: {
          username,
          orgid,
        },
      }),
    }),
    resetPassword: builder.query({
      query: ({ username }) => ({
        url: `api/v1/accounts/${username}/password-reset`,
        method: 'GET',
      }),
    }),
    logout: builder.query({
      query: () => ({
        url: 'api/v1/auth/logout',
        method: 'DELETE',
      }),
    }),
    updateUser: builder.mutation({
      query: ({ username, updates }) => ({
        url: `api/v1/accounts/${username}`,
        body: updates,
        method: 'PUT',
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useActivateAccountMutation,
  useLazyGetAccountQuery,
  useLazyResetPasswordQuery,
  useDeleteAccountMutation,
  useDeactivateAccountMutation,
  useGetAccountsQuery,
  useGetMyInfoQuery,
  useLazyLogoutQuery,
  useUpdateUserMutation,
} = api;
