import { avatarAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(avatarAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    background: '#7FB472',
    color: '#FFF',
    fontWeight: 'bold',
  },
});

export default defineMultiStyleConfig({ baseStyle });
