import React from 'react';
import Header from '../components/Header/Header';
import { Box, VStack } from '@chakra-ui/react';
import { useAppDispatch } from '../state/hooks';
import { useLazyLogoutQuery } from '../state/api';
import { loggedOut } from '../state/features/authState';

const Root: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const dispatch = useAppDispatch();
  const [logout] = useLazyLogoutQuery();
  const handleLogout = async () => {
    await logout({})
      .unwrap()
      .finally(() => {
        dispatch(loggedOut());
      });
  };
  return (
    <VStack
      maxW="100vw"
      justifyContent="flex-start"
      alignItems="flex-start"
      gap={0}
    >
      <Header onLogoutClick={handleLogout} />
      <Box h="full" w="full" bg="brand.100">
        {children}
      </Box>
    </VStack>
  );
};

export default Root;
