const colors = {
  primaryFontColor: {
    lightMode: '#4A5248',
    darkMode: '#FFFFFF',
  },
  brand: {
    100: '#DCE2DA',
    200: '#4A5248',
    300: '#CAD7C7',
    500: '#7FB472',
    600: '#707070',
    700: '#879D7F',
    750: '#4C4C4C',
    800: '#F26B6B',
    850: '#F26B6A',
    900: '#4A5248',
    red: '#FF3636',
  },
};
export default colors;
