import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  control: {
    _checked: {
      bgColor: '#7FB472',
      borderColor: '#7FB472',
    },
  },
});

export default defineMultiStyleConfig({ baseStyle });
