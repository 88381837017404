import * as React from 'react';
import styled from 'styled-components';
import logo from './logo.png';

const StyledImg = styled.img`
  height: 42px;
  width: 115px;
`;

const Logo = () => {
  return <StyledImg src={logo} />;
};

export default Logo;
