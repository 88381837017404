import React from 'react';
import styled from 'styled-components';
import Logo from '../Logo/Logo';
import {
  Avatar,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { useAppSelector } from '../../state/hooks';
import config from '../../config';
import { useGetMyInfoQuery } from '../../state/api';

const ProfileSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  align-items: center;
  gap: 1rem;
`;

interface HeaderProps {
  onLogoutClick: () => void;
}
const Header: React.FC<HeaderProps> = ({ onLogoutClick }) => {
  const isLoggedIn = useAppSelector((s) => s.auth.isLoggedIn);
  const { data } = useGetMyInfoQuery(
    {},
    {
      refetchOnReconnect: true,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );
  const name = data
    ? `${data?.data?.givenname} ${data?.data?.familyname}`
    : ' ';

  return (
    <HStack
      className={isLoggedIn ? 'with_dotted_bottom_border' : ''}
      h="97px"
      w="100vw"
      px="2rem"
      bg="brand.100"
    >
      <Logo />
      <Text fontSize={['10px', '20px', '24px', '37px']} color="#596157">
        Internal Admin Console - {config.env}
      </Text>
      {!isLoggedIn || (
        <Menu matchWidth>
          <MenuButton ml="auto">
            <ProfileSection>
              <Avatar name={`${name[0]}`} />
              {name}
            </ProfileSection>
          </MenuButton>
          <MenuList>
            <MenuItem onClick={onLogoutClick}>
              <Text color="#879D7F" fontSize={16}>
                Logout
              </Text>
            </MenuItem>
          </MenuList>
        </Menu>
      )}
    </HStack>
  );
};

export default Header;
