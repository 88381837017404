import React, { Suspense } from 'react';
import { CSSReset, ChakraProvider } from '@chakra-ui/react';
import './App.css';
import theme from './style/chakra/theme';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Root from './routes/Root';
import { Provider } from 'react-redux';
import { store } from './state/store';
import { useAppSelector } from './state/hooks';
import ProtectedRoute from './routes/components/ProtectedRoute';
const AccountDetails = React.lazy(() =>
  import('./routes/AccountDetails/AccountDetails')
);
const Login = React.lazy(() => import('./routes/Login'));
const Accounts = React.lazy(() => import('./routes/Accounts'));

const Main = () => {
  const isLoggedIn = useAppSelector((s) => s.auth.isLoggedIn);
  return (
    <Suspense>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <Accounts />
            </ProtectedRoute>
          }
        />
        <Route
          path="/accounts"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <Accounts />
            </ProtectedRoute>
          }
        />
        <Route
          path="/accounts/:username"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <AccountDetails />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Suspense>
  );
};

function App() {
  return (
    <ChakraProvider theme={theme}>
      <CSSReset />
      <Provider store={store}>
        <Root>
          <BrowserRouter>
            <Main />
          </BrowserRouter>
        </Root>
      </Provider>
    </ChakraProvider>
  );
}

export default App;
