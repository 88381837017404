import { ButtonProps, extendTheme } from '@chakra-ui/react';
import colors from './colors';
import avatar from './components/avatar';
import modal from './components/modal';
import _switch from './components/switch';
import text from './components/text';
import checkbox from './components/checkbox';
import radio from './components/radio';

const theme = extendTheme({
  fonts: {
    body: 'Montserrat, sans-serif',
    heading: 'Montserrat, sans-serif',
  },
  breakPoints: {
    sm: '320px',
    md: '768px',
    lg: '960px',
    xl: '1200px',
  },
  colors,
  components: {
    Checkbox: checkbox,
    Avatar: avatar,
    Radio: radio,
    Text: text,
    Modal: modal,
    Switch: _switch,
    Button: {
      variants: {
        ghost: (props: ButtonProps) => ({
          fontWeight: '500',
        }),
      },
    },
  },
  styles: {
    global: {
      html: {
        fontSize: '110%',
      },
      body: {
        color: '#596157',
        backgroundColor: '#FFF',
      },
    },
  },
});

export default theme;
