import { TextProps } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import colors from '../colors';

const TextTheme = {
  baseStyle: (props: TextProps) => ({
    color: mode(
      colors.primaryFontColor.lightMode,
      colors.primaryFontColor.darkMode
    ),
  }),
};

export default TextTheme;
