import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const dark = definePartsStyle({
  dialog: {
    background: '#4C4C4C',
    color: '#FFFFFF',
  },
});
const light = definePartsStyle({
  dialog: {
    background: '#FFFFFF',
  },
});

const modalTheme = defineMultiStyleConfig({
  variants: {
    dark,
    light,
  },
});
export default modalTheme;
